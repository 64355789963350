<template>
  <div class="container">
    <div class="lang">
      <el-select v-model="value" placeholder="Выберите" @change="clickChange">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </div>
    <h2> Лицензия на программное обеспечение UniLive и Сервисное соглашение </h2>
    <p>
      Введение <br />
      Соглашение о лицензировании и обслуживании программного обеспечения UniLive (далее именуемое « настоящее
      соглашение») - это PIX TECHNOLOGY PTE.
      Соглашение между Программным обеспечением UniLive под LTD (далее именуемым "UniLive" или "Мы") и Вами (далее
      именуемыми "Пользователями") относительно загрузки, установки, входа в систему, использования Программного
      обеспечения и использования связанных с UniLive услуг. Пожалуйста, внимательно ознакомьтесь с содержанием
      настоящего Соглашения. Если у вас есть вопросы о содержании настоящего Соглашения или подсказках на странице, не
      делайте никаких дальнейших действий. Вы можете проконсультироваться с официальным сервисом UniLive, чтобы мы могли
      объяснить и объяснить вам. Подтверждение другими способами, такими как щелчок по странице или прямое начало
      использования услуг, связанных с программным обеспечением UniLive, означает, что вы согласились с настоящим
      Соглашением.
      <br/>
    </p>
    <p>
      Если вы несовершеннолетнее лицо в возрасте до 18 лет или не обладаете полной гражданской правоспособностью по
      причине психического или психического здоровья, ознакомьтесь с настоящим Соглашением и другими соответствующими
      соглашениями под руководством и в сопровождении опекуна, уделяя особое внимание использованию несовершеннолетними,
      а также использованию программного обеспечения UniLive и связанных с ним услуг с согласия опекуна. Обратите особое
      внимание на то, что вы не должны использовать услуги, связанные с пополнением, предоставляемые UniLive, без явно
      выраженного согласия опекуна, а также просим опекуна эффективно выполнять свои обязанности по опеке.
    </p>
    <h3> Это соглашение поможет вам понять следующее </h3>
    <p> i, Определения </p>
    <p> II. Сфера применения и пересмотр Соглашения </p>
    <p> III. Правила лицензирования и использования программного обеспечения UniLive </p>
    <p> iv. Регистрация, использование и аннулирование учетной записи </p>
    <p> V, Защита персональных данных пользователей </p>
    <p> vi, Условия использования для несовершеннолетних </p>
    <p> VII, Кодекс поведения пользователя </p>
    <p> viii, спецификация использования данных </p>
    <p> IX, Налоговые обязательства по закону </p>
    <p> x, Интеллектуальная собственность </p>
    <p> XI, продвижение </p>
    <p> XII. Специальные соглашения по отдельным услугам </p>
    <p> XIII, Изменение, прерывание и прекращение обслуживания </p>
    <p> XIV, Заявление об освобождении от ответственности </p>
    <p> XV, обработка нарушений </p>
    <p> XVI, Прочее </p>
    <h3> i, определение </h3>
    <p>
      Платформа UniLive - это приложение UniLiveAPP, а также другие веб - сайты, веб - страницы, клиенты, небольшие
      программы и новые сервисные носители, которые могут быть открыты после технического прогресса.
    </p>
    <p>
      2. Сервис / услуга означает соответствующие услуги, предоставляемые пользователям через платформу UniLive
      (включая, но не ограничиваясь этим, предоставление пользователям доступа к сетевым аудио - и видеосюжетам, веб -
      трансляциям, поиску и т. д., а также к другим соответствующим услугам, предоставляемым в результате или для этой
      цели).
    </p>
    <p>
      Пользователи / пользователи UniLive, как зарегистрированные, так и незарегистрированные. Зарегистрированным
      пользователем является пользователь, который создает учетную запись UniLive для использования определенных функций
      и услуг платформы UniLive; Пользователи, которые не зарегистрировали наши продукты и / или услуги, становятся
      незарегистрированными пользователями с момента начала использования наших продуктов и / или услуг и должны
      соблюдать все условия настоящего Соглашения, за исключением исключительных соглашений зарегистрированных
      пользователей.
    </p>
    <p>
      Аккаунт / учетная запись UniLive - это учетная запись, которую вы регистрируете и используете на платформе UniLive
      и которая может быть реализована с помощью платформы UniLive, включая, но не ограничиваясь этим, одну или
      несколько функций, включая съемку в прямом эфире / короткое видео, публикацию, просмотр, комментарий, обмен, поиск
      и т.д.
    </p>
    <p>
      5.Правила платформы UniLive включают в себя все виды нормативных документов, таких как условия обслуживания,
      правила, нормы, интерпретация правил, правила реализации, уведомления, объявления и другие, которые были
      опубликованы или могут быть опубликованы UniLive в будущем, с поправками, в зависимости от отображения
      соответствующих страниц. В частности, при использовании определенных продуктов и / или услуг на платформе UniLive
      обращайте особое внимание на соответствующие условия или правила предоставления услуг. Если Вы соглашаетесь с
      настоящим Соглашением или фактически используете такие услуги, Вы соглашаетесь с вышеуказанными условиями.
    </p>
    <h3> ii. Сфера применения и пересмотр Соглашения </h3>
    <p>
      1. Сфера применения Соглашения, пожалуйста, знайте, что платформа UniLive является интегрированной сервисной
      платформой, и вы можете определить оператора платформы UniLive, с которым вы работаете, на основе соглашения,
      подписанного соответствующей службой, или информации, опубликованной соответствующей службой.
      В соответствии с настоящим Соглашением оператор платформы UniLive может быть изменен в соответствии с бизнес -
      изменениями платформы UniLive, и измененный оператор платформы UniLive будет работать с вами в соответствии с
      настоящим Соглашением и предоставлять вам услуги. Оператор платформы UniLive также может быть добавлен в
      результате предоставления новых услуг на платформе UniLive, например, если вы используете новые услуги, и
      считается, что вы согласны с тем, что оператор платформы UniLive, соответствующий новому сервису, будет работать с
      вами в соответствии с настоящим Соглашением. В случае возникновения спора по поводу использования службы в рамках
      платформы UniLive контрагентом в споре является оператор платформы UniLive, к которому относится та служба,
      которую вы используете.
    </p>
    <p>
      2. Сфера действия соглашения,
      Настоящее Соглашение включает правила платформы UniLive, является неотъемлемой частью настоящего Соглашения и
      имеет такую же юридическую силу, как и настоящее Соглашение.
      Кроме того, если вы используете продукты и / или услуги, предоставляемые третьими сторонами на нашей Платформе, вы
      должны соблюдать условия обслуживания третьих сторон в дополнение к положениям настоящего Соглашения.
    </p>
    <p>
      3. Пересмотр соглашения,
      Компания UniLive может своевременно внести изменения в настоящее Соглашение в соответствии с требованиями
      законодательства, национальной политики, технических условий, функциональных возможностей продукта и т.д. Как
      только вышеупомянутый контент будет официально выпущен, UniLive будет доставлен пользователям соответствующим
      образом (подсказки всплывающих окон, публикации веб - сайта, системные уведомления и т. Д. В зависимости от
      реальности). Если у вас есть вопросы относительно содержания измененного протокола или информации, содержащейся в
      подсказках на странице, вы можете проконсультироваться с официальным клиентом платформы UniLive, чтобы мы могли
      объяснить и объяснить это для вас. Подтвердите, щелкнув страницей или иным способом (например, продолжая
      использовать), что вы согласились с пересмотренным соглашением.
    </p>
    <h3> iii, лицензирование и использование программного обеспечения UniLive </h3>
    <p> 1. Объем лицензий на программное обеспечение </p>
    <p>
      1.1.
      Для использования Сервиса вам может потребоваться скачать Программное обеспечение UniLive, и для программного
      обеспечения UniLive и его технологий (если это применимо) UniLive предоставляет вам только одну личную, отзывную,
      непередаваемую и неисключительную лицензию. Вы можете загружать, устанавливать, использовать и запускать
      программное обеспечение UniLive только для целей использования Сервиса.
    </p>
    <p>
      1.2.
      Все другие права, прямо не разрешенные в настоящем разделе и других положениях настоящего Соглашения, остаются за
      компанией UniLive, и для осуществления этих прав необходимо получить дополнительную письменную лицензию от
      компании UniLive. Если UniLive не осуществляет каких - либо прав на оговорки, это не означает отказа от таких
      прав.
    </p>
    <p>
      2. Доступ к программному обеспечению
    </p>
    <p>
      2.1 Используя Программное обеспечение UniLive и связанные с ним сервисы, вы можете получить доступ к приложениям
      UniLive, в том числе через предустановку, загрузку из авторизованных третьих сторон, таких как App Store.
    </p>
    <p>
      2.2 Если вы получаете программное обеспечение UniLive от какой - либо третьей стороны, не являющейся компанией
      UniLive или не уполномоченной ею, UniLive не может гарантировать правильное использование программного обеспечения
      и не несет ответственности за убытки, понесенные вами в результате этого.
    </p>
    <p>
      Установка и удаление программного обеспечения
    </p>
    <p>
      3.1 В UniLive могут быть разработаны различные версии программного обеспечения для различных терминалов, систем и
      т.п. Вы можете выбрать подходящие версии для загрузки в зависимости от ситуации.
    </p>
    <p>
      3.2 После загрузки программы установки, пожалуйста, выполните шаги, указанные программой, чтобы установить ее
      правильно.
    </p>
    <p>
      3.3 Если вам больше не нужно использовать Программное обеспечение или вам нужно установить новую версию
      Программного обеспечения, вы можете удалить его самостоятельно. Если вы хотите помочь UniLive улучшить
      обслуживание продукта, мы с нетерпением ждем, когда вы сообщите причину удаления.
    </p>
    <p>
      4. Обновление программного обеспечения
    </p>
    <p>
      4.1 Чтобы улучшить пользовательский опыт и улучшить содержание сервиса, UniLive будет постоянно работать над
      разработкой новых сервисов и время от времени предоставлять вам обновления программного обеспечения (эти
      обновления могут принимать форму замены программного обеспечения, модификации, улучшения функций, обновления
      версий и т.д.).
    </p>
    <p>
      4.2 Для обеспечения безопасности и функциональной совместимости Программного обеспечения и Услуг UniLive имеет
      право обновлять Программное обеспечение или изменять или ограничивать некоторые функциональные эффекты
      Программного обеспечения без специального уведомления.
    </p>
    <p>
      4.3 После выпуска новой версии Программного обеспечения все или некоторые функции старой версии Программного
      обеспечения могут быть недоступны. UniLive не гарантирует, что старые версии программного обеспечения будут
      продолжать работать и предоставлять соответствующие клиентские услуги, поэтому, пожалуйста, загрузите последнюю
      версию вовремя.
    </p>
    <h3> IV. Регистрация, использование и аннулирование учетной записи </h3>
    <p>
      1. Регистрация
      Вы можете создать учетную запись UniLive для использования некоторых функций и услуг нашей платформы. Вы можете
      зарегистрироваться / войти в учетную запись UniLive непосредственно на нашей платформе.
    </p>
    <p>
      2. Использование
    </p>
    <p>
      2.1 Пользователь несет ответственность за сохранность информации учетной записи UniLive и пароля учетной записи, а
      также несет юридическую ответственность за действия под учетной записью UniLive и паролем. Пользователь
      соглашается ни при каких обстоятельствах не разглашать информацию о своей учетной записи и пароле другим лицам,
      зная, что UniLive не запрашивает у вас пароль учетной записи. Если вы подозреваете, что ваша учетная запись была
      украдена, сообщите об этом UniLive немедленно. Что касается безопасности учетных записей, то UniLive будет
      стремиться защитить вашу учетную запись на сервере на нынешнем техническом уровне и постоянно обновлять
      технические меры. Убытки и последствия, вызванные вашей инициативой раскрытия информации или тем, что вы
      подверглись нападению со стороны других лиц, мошенничеству и другим действиям, вы можете взыскать с нарушителей с
      помощью судебных, административных и других средств правовой защиты. Если вам нужна помощь UniLive, UniLive
      сделает все возможное, чтобы помочь в соответствии с действующими правовыми нормами.
    </p>
    <p>
      2.2 Право собственности на учетную запись UniLive принадлежит Beijing UniLive Technology Co., Ltd. После того, как
      пользователь завершил процесс регистрации, зарегистрированная учетная запись UniLive доступна только вам. В то же
      время, поскольку действия вашей учетной записи (включая, но не ограничиваясь этим, подписание различных
      соглашений, публикацию информации, покупку товаров и услуг и раскрытие информации в Интернете) представляют собой
      ваши собственные действия, вы должны хранить информацию о своей учетной записи и пароли и нести ответственность за
      результаты действий вашей учетной записи. Вы не можете ни в какой форме предоставлять, сдавать в аренду,
      передавать, продавать или иным образом разрешать другим лицам использовать учетную запись UniLive без письменного
      согласия UniLive. Если ваша учетная запись подвергается действию или используется не вами лично, пожалуйста,
      немедленно уведомите UniLive, чтобы избежать потерь и последствий расширения; Если UniLive определяет, что
      использование учетной записи UniLive может поставить под угрозу безопасность вашей учетной записи и / или
      безопасность информации о программном обеспечении UniLive, вы соглашаетесь с тем, что UniLive может приостановить
      предоставление соответствующих услуг до тех пор, пока вы лично не обратитесь с просьбой о восстановлении и пока
      эта учетная запись не устранит угрозу информационной безопасности программного обеспечения UniLive.
    </p>
    <p>
      2.3 Вы понимаете и обязуетесь, что информация о Вашей учетной записи UniLive не должна противоречить национальным
      законам, правилам и правилам, регулирующим услуги, предоставляемые UniLive. В регистрационной информации, такой
      как имя учетной записи, номер UniLive, аватар, профиль, профили и личные данные, которые вы установили, не должно
      быть незаконной или вредной информации, учетная запись UniLive не может быть зарегистрирована от имени другого
      лица (включая, но не ограничиваясь этим, использование чужих имен, имен, товарных знаков, знаков, портретов,
      аватаров и других уникальных идентифицирующих элементов или достаточно запутанных способов) без разрешения другого
      лица.
    </p>
    <p>
      2.4 В соответствии с соответствующими национальными законами и правилами и требованиями нормативной политики, Для
      того, чтобы общественность могла осуществлять надзор и создавать хороший общественный порядок в общественных
      интересах, UniLive будет показывать на странице личной информации вашей учетной записи и в других местах
      информацию о том, где вы используете учетную запись в разумном диапазоне адреса интернет - протокола (IP),
      указывая местоположение, форму, тип информации и другие соответствующие механизмы отображения в соответствии с
      соответствующими законами и правилами и фактическими условиями отображения, которые вы полностью знаете и
      согласны.
    </p>
    <p>
      2.5 В целях обеспечения безопасности учетной записи и предотвращения кражи учетной записи мы можем время от
      времени использовать один или несколько способов аутентификации зарегистрированного пользователя (например,
      проверку почты и т.д.). Если пользователь не прошел проверку, у нас есть разумные основания подозревать, что
      учетная запись является небезопасной, такой как кража, и мы можем в одностороннем порядке принять решение о
      приостановлении или прекращении дальнейшего предоставления наших продуктов и / или услуг на учетную запись в
      зависимости от обстоятельств. Вы соглашаетесь с тем, что мы принимаем вышеуказанные меры для защиты учетной записи
      пользователя, и мы не несем необоснованной ответственности за это.
    </p>
    <p>
      2.6 Если пользователь не регистрирует учетную запись UniLive в течение двух месяцев подряд, UniLive имеет право
      заморозить, восстановить, заменить или аннулировать учетную запись, а также принять меры по очистке, такие как
      удаление и очистка любых записей учетной записи в базе программного обеспечения UniLive (включая, в частности,
      регистрационную информацию, виртуальные активы и т.д.
    </p>
    <p>
      2.7 Если ваша учетная запись заблокирована, вы можете повторно активировать свою учетную запись с помощью других
      способов проверки, предоставляемых платформой UniLive.
    </p>
    <p>
      3. Списание
      Мы предоставляем вам возможность аннулирования учетной записи UniLive, и вы можете аннулировать свою учетную
      запись онлайн - способом, доступным на платформе UniLive, связаться с нашими клиентами или иным способом, который
      мы предлагаем.
    </p>
    <h3> 5, Защита персональных данных пользователей </h3>

    <p>
      Понимая важность личной информации для вас, мы придаем большое значение защите вашей конфиденциальности и личной
      информации, а также рассматриваем и обрабатываем вашу личную информацию с высокой степенью осторожности. При
      использовании наших продуктов и / или услуг мы будем применять соответствующие технические и другие меры
      безопасности для защиты вашей личной информации.
    </p>
    <p>
      При регистрации Вашей учетной записи или использовании Услуг мы просим Вас предоставить необходимую информацию,
      такую как Ваш почтовый ящик, которая не может быть использована или ограничена в процессе использования, если Вы
      не согласны с авторизацией Персональных данных, необходимых для Услуг.
    </p>
    <p>
      Мы придаем большое значение управлению вашей персональной информацией и делаем все возможное для защиты ваших прав
      на доступ, копирование, исправление, дополнение, удаление, отзыв разрешения на согласие и аннулирование учетной
      записи, сообщения о жалобах и т. Д. в отношении вашей персональной информации, чтобы вы могли понять и
      гарантировать безопасность вашей персональной информации.
    </p>
    <p>
      Мы уделяем большое внимание защите личной информации несовершеннолетних. Если вы являетесь несовершеннолетним в
      возрасте до 18 лет, вам необходимо получить предварительное согласие родителей или других опекунов на
      использование услуг UniLive.
    </p>
    <p>
      Для получения дополнительной информации о том, как UniLive собирает, использует, хранит и защищает вашу личную
      информацию и какие права у вас есть, посетите Политику конфиденциальности UniLive.
    </p>
    <h3> vi, Условия использования для несовершеннолетних </h3>
    <p>
      Если вы являетесь несовершеннолетним в возрасте до 18 лет, вы должны ознакомиться с настоящим Соглашением и
      использовать Программное обеспечение UniLive и связанные с ним услуги под опекой, руководством и с согласия
      опекуна.
    </p>
    <p>
      Компания UniLive уделяет большое внимание защите персональных данных несовершеннолетних, и при заполнении
      персональных данных несовершеннолетним пользователям следует проявлять повышенную осведомленность и осторожность,
      а также правильно использовать программное обеспечение UniLive и связанные с ним услуги под руководством опекуна.
    </p>
    <p>
      Несовершеннолетние, использующие Программное обеспечение и связанные с ним услуги, должны под надзором своих
      опекунов в разумных пределах правильно учиться использовать Интернет, избегать пристрастия к виртуальному
      киберпространству, развивать хорошие привычки в Интернете, хорошо учиться в Интернете, не просматривать плохую
      информацию; Будьте честны и дружелюбны в общении, не оскорбляйте и не обманывайте других; Чтобы повысить
      осведомленность о самозащите, не случайным образом встречаться с пользователями сети; Необходимо поддерживать
      сетевую безопасность, не нарушать сетевой порядок; Чтобы быть полезным для физического и психического здоровья, не
      предавайтесь виртуальному пространству - времени.
    </p>
    <p>
      Ваш опекун может использовать такие функции, как пополнение, вознаграждение и т.д. при использовании услуг,
      предоставляемых UniLive. Как опекун, пожалуйста, храните свой платежный счет, чтобы избежать использования
      опекуном функции пополнения и вознаграждения через ваш платежный счет без вашего согласия. Компания UniLive готова
      сотрудничать с вами, чтобы избежать подобных действий.
    </p>
    <p>
      Чтобы лучше защитить права и интересы конфиденциальности несовершеннолетних, UniLive напоминает вам о
      необходимости проявлять осторожность при публикации контента, содержащего изображения несовершеннолетнего, и после
      публикации считается, что вы получили согласие правообладателя показать портрет, голос и другую информацию
      несовершеннолетнего, а также разрешить UniLive использовать и управлять контентом, связанным с несовершеннолетним,
      в соответствии с настоящим Соглашением. Если правообладатель уведомляет UniLive о том, что опубликованный вами
      контент нарушает права несовершеннолетних, или по другим соображениям защиты прав несовершеннолетних, UniLive
      имеет право обрабатывать и уведомлять вас о размещенном вами контенте.
    </p>
    <h3> 7, Правила поведения пользователя </h3>
    <p>
      Вы несете ответственность за использование Программного обеспечения UniLive и связанных с ним услуг, за
      исключением случаев, когда это разрешено законом или с предварительного письменного разрешения UniLive,
      использование Программного обеспечения UniLive и связанных с ним услуг не может быть связано с:
    </p>
    <p>
      1.1 Использование любых плагинов, плагинов, систем или сторонних инструментов, которые не лицензированы или не
      лицензированы UniLive, для вмешательства, нарушения, изменения или иного воздействия на нормальную работу
      программного обеспечения UniLive и связанных с ним служб.
    </p>
    <p>
      1.2 Использование или использование программного обеспечения UniLive и связанных с ним услуг для совершения любых
      действий, которые ставят под угрозу безопасность компьютерных сетей, включая, но не ограничиваясь этим:
    </p>
    <p>
      1.2.1 Незаконное проникновение в сети других лиц, нарушение нормального функционирования сети других лиц, кража
      сетевых данных и другие действия, ставящие под угрозу кибербезопасность;
      1.2.2 Предоставление программ, инструментов, специально предназначенных для осуществления деятельности, угрожающей
      кибербезопасности, такой как вторжение в сеть, нарушение нормального функционирования сети и защитных мер, кража
      сетевых данных и т.д.
      1.2.3 Если известно, что другие лица занимаются деятельностью, которая ставит под угрозу кибербезопасность,
      предоставить им техническую поддержку, рекламу, оплату и расчеты и другую помощь;
      1.2.4 Использование нелицензированных данных или доступ к нелицензированным серверам / учетным записям;
      1.2.5 Несанкционированный доступ к общедоступным компьютерным сетям или компьютерным системам других лиц и
      удаление, изменение или добавление хранимой информации;
      1.2.6 Несанкционированные попытки обнаружения, сканирования, проверки уязвимости системы или сети UniLive или
      другие действия, подрывающие сетевую безопасность;
      1.2.7 Попытка вмешательства или нарушения нормального функционирования системы UniLive или веб - сайта,
      преднамеренное распространение вредоносных программ или вирусов и другие действия, нарушающие нормальное
      функционирование информационных служб сети;
      1.2.8 Подделка имени пакета TCP / IP или его части.
    </p>
    <p>
      1.3 Обратная инженерия, обратная компиляция, компиляция или другая попытка найти исходный код программного
      обеспечения UniLive.
    </p>
    <p>
      1.4 Приобретать, копировать, изменять, добавлять, удалять, монтировать или создавать любые производные данные о
      данных, высвобождаемых Программным обеспечением UniLive или Программным обеспечением UniLive в любую конечную
      память во время его работы, о взаимодействии между клиентом и сервером во время работы программного обеспечения, а
      также системные данные, необходимые для работы Программного обеспечения UniLive, в том числе, но не ограничиваясь
      этим, в форме использования плагинов, плагинов, группового контроля или доступа к Программному обеспечению UniLive
      и связанным с ним системам сторонними инструментами / службами, не имеющими лицензии UniLive.
    </p>
    <p>
      1.5 Добавлять, удалять, изменять функции или операционные эффекты программного обеспечения UniLive путем изменения
      или фальсификации инструкций или данных, используемых в программном обеспечении UniLive, или эксплуатировать
      программное обеспечение или методы, используемые для этих целей, или распространять их среди общественности,
      независимо от того, являются ли эти действия коммерческими или нет.
    </p>
    <p>
      1.6 Удалить информацию об интеллектуальной собственности из программного обеспечения UniLive (и его копий) и
      связанного с ним контента (например, коротких видео) или изменить, удалить или обойти любые технические меры,
      установленные для защиты интеллектуальной собственности.
    </p>
    <p>
      1.7 Использование, аренда, заимствование, копирование, изменение, ссылка, воспроизведение, компиляция, публикация,
      публикация, вертикальный поиск, создание зеркальных сайтов и т.д. контента, на который UniLive обладает правами
      интеллектуальной собственности или который публикуется другими пользователями на платформе UniLive.
    </p>
    <p>
      1.8 Использование учетной записи UniLive для участия в любой деятельности или транзакции, которые являются
      незаконными или потенциально незаконными (по определению UniLive), включая обучение методам совершения
      преступлений, продажу любых незаконных наркотиков, отмывание денег, мошенничество и т.д.
    </p>
    <p>
      1.9 Нарушение законодательства, настоящего Соглашения, правил платформы UniLive и других действий, нарушающих
      законные права и интересы других лиц.
      Если у UniLive есть разумные основания полагать, что какие - либо из ваших действий нарушают или могут нарушить
      вышеупомянутое соглашение, UniLive может принять независимое решение и принять необходимые меры для решения этой
      проблемы.
    </p>
    <p>
      2. Нормы информационного содержания
    </p>
    <p>
      2.1 Информационный контент, описанный в настоящей статье, означает любой контент, созданный, скопированный,
      опубликованный или распространенный пользователем в процессе использования Программного обеспечения и Услуг,
      включая, но не ограничиваясь этим, информацию, отображаемую на личной домашней странице, такую как аватары,
      прозвища, личные презентации учетных записей UniLive, или тексты, фотографии, аудио - и видеоматериалы, прямые
      трансляции, опубликованные или распространенные, а также другой контент, созданный с использованием учетных
      записей UniLive или Программного обеспечения и Услуг.
    </p>
    <p>
      2.2 Вы понимаете и соглашаетесь с тем, что компания UniLive всегда стремилась обеспечить своим пользователям
      цивилизованную, здоровую и упорядоченную сетевую среду и что вы не должны использовать учетную запись UniLive или
      Программное обеспечение и Услуги для создания, копирования, публикации и распространения контента, который
      нарушает нормальную работу UniLive и нарушает законные интересы других пользователей или третьих лиц.
    </p>
    <p>2.3 Основные правила загрузки видео на UniLive</p>
		<p>
			Руководство по контенту (стандарты сообщества)
		</p>
		<p>
			Запрещён насильственный контент: нельзя загружать видео с демонстрацией насилия, причинения вреда, жестокого обращения или терроризма, включая реальные и инсценированные сцены.  
		</p>
		<p>
			Запрещён порнографический и откровенный контент: обнажённые сцены, сексуальные намёки, порнография или любой другой контент для взрослых строго запрещены.  
		</p>

		<p>
			Запрещены выражения ненависти: нельзя публиковать контент, направленный на дискриминацию, нападки или разжигание ненависти по признаку расы, религии, пола, сексуальной ориентации, национальности и т. д.  
		</p>
		<p>
			Запрещены запугивание и домогательства: нельзя загружать контент, унижающий, угрожающий или преследующий других людей, включая ненадлежащее поведение в отношении детей.  
		</p>
		<p>
			Запрещены опасные действия: нельзя демонстрировать видео с опасными челленджами, розыгрышами или незаконной деятельностью (например, употребление наркотиков, использование оружия), которые могут привести к травмам.  
		</p>
    <p>
      3. Нормы участия в деятельности
    </p>
    <p>
      Третьи стороны на платформах UniLive или UniLive будут проводить различные мероприятия нерегулярно. Прежде чем
      участвовать в мероприятии, вы должны перейти на соответствующую страницу мероприятия, чтобы внимательно прочитать
      и полностью понять правила деятельности. Если вы участвуете в мероприятии, вы считаете, что полностью прочитали и
      поняли правила деятельности и добровольно подчиняетесь правилам деятельности. Во время чтения правил деятельности,
      если вы не понимаете или не согласны с чем - либо из них, прекратите участвовать в соответствующей деятельности.
      Пожалуйста, знайте, что система управления ветром автоматически проверяет и удаляет пользователей, которые
      участвуют в деятельности ненадлежащим образом (участников деятельности, которые нарушают запреты, содержащиеся в
      настоящем Соглашении или Правилах деятельности). Вы соглашаетесь с тем, что UniLive имеет право самостоятельно
      определять, были ли нарушения, основанные на возможностях анализа больших данных и технической идентификации вашей
      платформы, и дисквалифицировать пользователей, которые участвовали в деятельности ненадлежащим образом, и
      дисквалифицировать их от участия в деятельности, наград и наград, а также не признавать результаты, полученные с
      использованием неправомерных средств, и в то же время иметь право на возмещение уже выплаченных вознаграждений.
    </p>
    <p>
      4. Ответственность за свои действия
    </p>
    <p>
      Вы понимаете и соглашаетесь с тем, что вы несете ответственность за действия под своей зарегистрированной учетной
      записью, включая любой контент, комментарии и похвалы, рекомендации, поиск и другие действия, которые вы
      публикуете, а также любые последствия, которые могут возникнуть в результате этого. Вы сами оцениваете содержимое
      Сервиса и принимаете на себя все риски, связанные с его использованием, включая риски, связанные с зависимостью от
      законности, правильности, целостности или полезности содержимого. Компания UniLive не может и не будет нести
      ответственность за потери или ущерб, вызванные указанными выше рисками.
    </p>
    <p>
      Компания UniLive уважает и защищает ваши и другие законные права на интеллектуальную собственность, репутацию, имя
      и конфиденциальность. Вы гарантируете, что текст, фотографии, аудио - и видеоматериалы, прямые трансляции, ссылки
      и т. Д., загружаемые при использовании программного обеспечения UniLive и связанных с ним услуг, не нарушают права
      интеллектуальной собственности, репутации, имени, конфиденциальности и т. Д. В противном случае UniLive имеет
      право удалить предполагаемый контент с уведомлением правообладателя или заинтересованной стороны. В ответ на все
      претензии третьих лиц вы должны сами справиться с ними и взять на себя всю юридическую ответственность, которая
      может возникнуть в результате этого; В случае убытков, понесенных компанией UniLive или ее аффилированными лицами
      в результате нарушения Вами своих прав (включая, в частности, убытки в сфере экономики, гудвилла и т.п.
    </p>
    <h3> viii, спецификация использования данных </h3>
    <p>
      Без письменного разрешения UniLive Пользователь не может разрешать, разрешать или помогать третьим лицам в
      отношении информационного контента в Программном обеспечении UniLive и связанных с ним услугах:
    </p>
    <p>
      1.1 Копирование, чтение, использование информационного содержания программного обеспечения UniLive и связанных с
      ним услуг, включая, но не ограничиваясь этим, коммерческие цели, такие как реклама, увеличение количества
      читателей и просмотров;
    </p>
    <p>
      1.2 Несанкционированное редактирование, систематизация и конфигурация информационного контента программного
      обеспечения UniLive и связанных с ним услуг и его демонстрация по каналам, отличным от исходных страниц
      программного обеспечения UniLive и связанных с ним услуг;
    </p>
    <p>
      1.3 Использовать любую форму идентификации, включая, в частности, специальные идентификаторы, специальные коды,
      для совершения неправомерных действий, таких как трафик, загрузка просмотра, передача, захват и т.д. информации
      или контента программного обеспечения UniLive и связанных с ним услуг, самостоятельно или при содействии третьих
      сторон;
    </p>
    <p>
      1.4 Другие действия, связанные с незаконным доступом к информационному контенту программного обеспечения UniLive и
      связанных с ним услуг.
    </p>
    <p>
      После получения письменного разрешения от UniLive пользовательские действия, такие как обмен и пересылка
      информации и контента о программном обеспечении UniLive и связанных с ним услугах, также должны соответствовать
      следующим нормам:
    </p>
    <p>
      2.1 В отношении данных, связанных с захватом, статистикой, полученными соответствующими горячими словами поиска,
      частотой попаданий, классификацией, количеством поиска, частотой кликов, количеством просмотров и т.д.,
      вышеуказанные данные не могут быть обнародованы, предоставлены или раскрыты каким - либо образом какой - либо
      третьей стороне без предварительного письменного согласия UniLive;
    </p>
    <p>
      2.2 Никакие изменения исходной страницы программного обеспечения UniLive и связанных с ним услуг не должны
      вноситься в какой бы то ни было форме, включая, в частности, входы на главную страницу (профиль) программного
      обеспечения UniLive и связанных с ним услуг, ссылки на рекламные системы и т.д., а также любые препятствия для
      отображения исходной страницы программного обеспечения UniLive и связанных с ним услуг, такие как блокировка,
      вставка, всплывающие окна и т.п.
    </p>
    <p>
      2.3 Должны быть приняты безопасные, эффективные и строгие меры для предотвращения незаконного доступа третьих
      сторон к информационному контенту программного обеспечения UniLive и связанных с ним услуг в любой форме, включая,
      в частности, программы « Паук» (spider).
    </p>
    <p>
      2.4 Материалы данных не должны использоваться в целях, не подпадающих под действие письменной лицензии UniLive,
      для продажи или коммерческого использования в какой бы то ни было форме или для разглашения, предоставления или
      разрешения использования третьими лицами в какой бы то ни было форме;
    </p>
    <p>
      2.5 Пользователи, которые передают, пересылают и копируют информацию о программном обеспечении UniLive и связанных
      с ним услугах любой третьей стороне, должны также соблюдать другие нормы и стандарты, установленные UniLive для
      этой цели.
    </p>
    <h3> IX, Налоговые обязательства по закону </h3>
    <p>
      Соответствующие доходы, такие как доход или вознаграждение, полученные вами на платформе UniLive (далее именуемые
      « соответствующие поступления»), могут быть переведены на ваш платежный счет. Согласно соответствующим
      национальным законам, административным правилам и нормативным актам о налогообложении, доходы, которые вы
      получаете на платформе UniLive, подлежат налогообложению или декларированию в соответствии с законом. Вы понимаете
      и соглашаетесь с тем, что Платформа UniLive, в соответствии с законом, должна собирать или использовать вашу
      личную и налоговую информацию (в том числе не ограничиваясь реальными именами, идентификационными данными и
      контактными данными), когда она удерживает вас от имени налогового органа или подает налоговую декларацию от
      вашего имени в соответствии с требованиями налогового органа, и что вы должны активно выполнять свои налоговые
      обязательства в сотрудничестве с Платформой UniLive.
    </p>
    <h3> 10, Интеллектуальная собственность </h3>
    <p>
      1.Права интеллектуальной собственности на контент, предоставляемый UniLive в этом программном обеспечении и
      связанных с ним услугах (включая, но не ограничиваясь этим, программное обеспечение, технологии, программы, веб -
      страницы, текст, изображения, аудио, видео, графики, макеты, электронные документы и т.д.), принадлежат UniLive.
      Авторские права, права на товарные знаки, патенты, коммерческую тайну и другие права интеллектуальной
      собственности на программное обеспечение, на котором компания UniLive предоставляет услуги, принадлежат компании
      UniLive. Никто не может использовать и передавать без разрешения UniLive (включая, но не ограничиваясь этим,
      мониторинг, копирование, распространение, демонстрацию, зеркальное отображение, загрузку, загрузку содержимого
      Программного обеспечения и связанных с ним услуг с помощью любых роботов, пауков и других программ или устройств).
    </p>
    <p>
      Вы гарантируете, что публикация загруженного текста, изображений, видео, аудио и т. Д. при использовании
      Программного обеспечения и связанных с ним услуг является оригинальной или юридически санкционированной (включая
      трансферную лицензию) и что интеллектуальная собственность на контент, который вы загружаете и публикуете через
      Программное обеспечение UniLive, принадлежит вам или первоначальному правообладателю.
    </p>
    <p>
      В целях улучшения обмена и популяризации ваших работ и расширения влияния вас и ваших работ, если вы не заключили
      с UniLive иного письменного соглашения, вы знаете, понимаете и соглашаетесь предоставить UniLive и ее
      аффилированным компаниям бесплатные, неисключительные и многоуровневые права на использование (включая права на
      копирование, адаптацию, воспроизведение, адаптацию, адаптацию и перевод) авторских прав и соответствующих
      конкурентных прав на контент, который вы публикуете / загружаете при использовании программного обеспечения
      UniLive и связанных с ним услуг, включая, в частности, тексты, фотографии, видео, аудио и другие материалы, а
      также элементы, включенные в вышеуказанные результаты, но не ограничиваясь ими. Право на компиляцию, право на
      исполнение, право на распространение информационных сетей, право на вещание, производство производных и т.д.),
      Предыдущая лицензия распространяется на существующие и будущие носители, такие как платформа UniLive или другие
      сторонние платформы, приложения, продукты или терминальные устройства, веб - страницы и т. Д. Вы соглашаетесь с
      тем, что UNILive может использовать или разрабатывать (полностью или частично) вышеупомянутый контент по своей
      собственной инициативе или по лицензии третьих лиц для продвижения или обновления продукта / функции, а также для
      исследования нового продукта / функции, и обязуетесь не требовать каких - либо личных прав или вознаграждения за
      использование или разработку UNILive вышеупомянутого контента. В соответствии с функциями некоторых служб контент,
      загружаемый через программное обеспечение UniLive, может быть использован другими пользователями UniLive для
      создания и публикации контента с помощью программного обеспечения UniLive. Произведения, созданные пользователями
      в соответствии с вышеуказанным соглашением, могут распространяться только на платформах UniLive или сторонних
      платформах, сотрудничающих с UniLive, и не могут использоваться в коммерческих целях без согласия UniLive. Если вы
      не хотите, чтобы UniLive продолжал использовать полностью или частично лицензированный контент, вы можете отозвать
      эту лицензию, уведомив UniLive об этом путем публикации.
    </p>
    <p>
      В целях повышения экспозиции и эффективности публикации Ваших работ Вы соглашаетесь с тем, что все, что Вы
      публикуете при использовании Программного обеспечения UniLive и связанных с ним услуг, разрешает UniLive
      автоматически и синхронно публиковать с Вашей учетной записью на других сервисных носителях, таких как клиенты,
      веб - сайты, веб - страницы и микропрограммы, которыми управляет Beijing UniLive Technology Co., Ltd. или ее
      аффилированные компании, и что заголовок Вашей учетной записи, прозвище и другая общедоступная неконфиденциальная
      информация могут быть Если вы не хотите продолжать авторизацию UniLive для автоматической синхронизации
      публикуемого контента, вы можете отозвать эту лицензию самостоятельно с помощью подсказки на странице операций,
      связанной с программным обеспечением UniLive, или уведомить UniLive об отзыве этой лицензии другим публичным
      способом.
    </p>
    <p>
      Вы подтверждаете и соглашаетесь разрешить UniLive от своего собственного имени или поручить третьим лицам
      осуществлять репрезентативные права на контент, который нарушает права интеллектуальной собственности или законно
      лицензирован и который вы загружаете, при условии, что по запросу UniLive вам будет предоставлена соответствующая
      подтверждающая документация. Формы защиты прав включают, но не ограничиваются ими: мониторинг нарушений, отправка
      писем о защите прав, подача иска или арбитража, посредничество, примирение и т. Д., И UniLive имеет право
      принимать решения по вопросам защиты прав и осуществлять их независимо.
    </p>
    <p>
      Если вы обнаружите, что программное обеспечение и связанные с ним веб - сайты содержат контент, который нарушает
      ваши права, немедленно пройдите по почтовому ящику. unilive.team@gmail.com Уведомляя UniLive и предоставляя
      доказательства prima facie в отношении ваших прав, UniLive своевременно рассматривает ваши жалобы в соответствии с
      законом.
    </p>
    <p>
      Компания UniLive оказывает техническую поддержку в разработке и эксплуатации Программного обеспечения и связанных
      с ним услуг и обладает всеми правами на все данные и информацию, получаемые в ходе разработки и эксплуатации
      Программного обеспечения и связанных с ним услуг.
    </p>
    <p>
      Пожалуйста, ни в коем случае не используйте в частном порядке любые торговые марки, знаки обслуживания, торговые
      марки, доменные имена, названия веб - сайтов или другие отличительные особенности бренда UniLive, включая "UniLive
      Technology" "UniLive" "Unilive.com" и т. д. (далее именуемые "логотипами"). Без предварительного письменного
      согласия UniLive вы не можете демонстрировать, использовать или иным образом обрабатывать идентификаторы,
      упомянутые в настоящих Условиях, индивидуально или в сочетании. Вы несете полную юридическую ответственность за
      ущерб, причиненный UniLive или другим лицам в результате использования вами вышеуказанных товарных знаков,
      логотипов и т.д. компании UniLive в нарушение настоящего Соглашения.
    </p>
    <h3> XI, продвижение </h3>
    <p>
      Вы понимаете и соглашаетесь с тем, что при предоставлении Программного обеспечения UniLive и связанных с ним услуг
      мы оставляем за собой право самостоятельно или нашими партнерами размещать и / или монтировать коммерческие
      объявления (включая рекламные ссылки) или любую другую коммерческую и некоммерческую информацию (более известную
      как « информация о продвижении») несколькими способами. Это право включает, но не ограничивается этим, размещение
      и / или монтирование рекламных материалов UniLive в любом месте на платформе UniLive, где вы загружаете,
      распространяете контент и учетную запись. В соответствии с законами и правилами форма, продолжительность,
      местоположение и содержание, в которых размещается и / или монтируется информация, не подлежат вмешательству со
      стороны какой - либо третьей стороны.
    </p>
    <p>
      2. О коммерческой рекламе
    </p>
    <p>
      2.1 Если вы не желаете получать релевантную рекламу, вы имеете право « не интересоваться» этим выбором рекламных
      сообщений, и количество push подобных объявлений в этой рекламе будет уменьшено.
    </p>
    <p>
      2.2 Мы выполняем обязательства, связанные с рекламой, в соответствии с законом, но вы понимаете и соглашаетесь с
      тем, что рекламные партнеры и содержание рекламы не находятся под нашим контролем, и вы должны тщательно оценивать
      их подлинность и надежность в отношении содержания рекламы. Мы не несем никакой дополнительной ответственности за
      любые действия или споры, которые вы совершаете через наших партнеров по продуктам и / или услугам и рекламе, за
      которые вы несете ответственность и разрешаете их, кроме тех, которые предусмотрены законом и правилами, но при
      необходимости оказываете необходимую помощь в соответствии с законом.
    </p>
    <p>
      2.3 Если вы не хотите получать push - уведомления от UniLive, вы имеете право отключить эту службу самостоятельно
      в управлении уведомлениями мобильной системы.
    </p>
    <h3> XII, Специальные соглашения по отдельным услугам </h3>
    <p>
      Программное обеспечение UniLive и связанные с ним сервисы содержат информацию или ссылки на информационный
      контент, которые UniLive получает различными законными способами, а также другие отдельные сервисы, которые
      UniLive и ее аффилированные лица используют на законных основаниях. Вы можете включить и использовать эти функции
      в программном обеспечении UniLive. Некоторые отдельные услуги могут потребовать от вас одновременного принятия
      соглашений или других правил, регулирующих отношения между вами и поставщиком услуг. UniLive предоставит вам
      доступ к этим протоколам и правилам в привлекательной форме. После того, как вы соглашаетесь с соглашением или
      начинаете использовать вышеуказанные услуги, считается, что вы одновременно принимаете соответствующие соглашения
      и правила, касающиеся отдельных услуг.
    </p>
    <p>
      При использовании Программного обеспечения и / или связанных с ним услуг, предоставляемых третьими сторонами в
      Программном обеспечении UniLive, в дополнение к настоящему Соглашению и Правилам Платформы UniLive вы должны
      соблюдать соглашения третьих сторон и соответствующие правила. В случае споров, потерь или ущерба, связанных с
      программным обеспечением и / или связанными с ним услугами третьих сторон, которые разрешаются вами и третьими
      сторонами, UniLive не несет ответственности перед вами или любой третьей стороной.
    </p>
    <h3> XIII, Изменение, прерывание и прекращение обслуживания </h3>
    <p>
      В той мере, в какой это допускается законами и правилами, мы можем временно или постоянно изменять или прекращать
      наши продукты и / или услуги (или любую их часть) в соответствии с развитием нашего бизнеса и не нести
      ответственности за нарушение обязательств перед вами и любой третьей стороной после предварительного уведомления в
      письменной форме, такой как письмо на сайте, уведомление об обновлении / прекращении в течение разумного периода
      времени.
    </p>
    <p>
      В дополнение к вышесказанному, мы имеем право приостановить или прекратить предоставление Вам Продуктов и / или
      Услуг, если:
    </p>
    <p>
      2.1 Вы сами обратились с просьбой;
    </p>
    <p>
      2.2 Вы существуете или, по нашему независимому мнению, совершаете какие - либо действия, которые нарушают
      национальные законы, правила или нормативные акты, нарушают настоящее Соглашение или наносят ущерб нашей репутации
      и интересам;
    </p>
    <p>
      2.3 В соответствии с положениями законодательства, нормативными актами, политикой регулирования или требованиями
      компетентного органа;
    </p>
    <p>
      2.4 Для обеспечения безопасности учетных записей и систем в чрезвычайных ситуациях;
    </p>
    <p>
      2.5 Форс - мажор (форс - мажор - это непредвиденная, неизбежная и непреодолимая объективная ситуация, которая,
      учитывая особый характер Интернета, также включает хакерские атаки, значительные последствия, вызванные
      техническими корректировками в телекоммуникационном секторе, временное отключение из - за государственного
      контроля, вирусное вторжение и другие обстоятельства, влияющие на нормальное функционирование Интернета);
    </p>
    <p>
      2.6 Другие обстоятельства непреодолимой силы.
    </p>
    <p>
      3. В случае прекращения действия, указанного выше, Вы и мы согласны со следующим образом:
    </p>
    <p>
      3.1 Если у вас есть текущие транзакции на нашей Платформе, мы будем надлежащим образом обрабатывать их в этом
      случае;
    </p>
    <p>
      3.2 В случае прекращения действия в результате нарушения Вами настоящего Соглашения мы имеем право потребовать от
      Вас ответственности за соответствующее нарушение;
    </p>
    <p>
      3.3 Мы не несем никакой ответственности перед Вами и третьими лицами, если иное не предусмотрено законом или нами.
    </p>
    <h3> XIV, Заявление об освобождении от ответственности </h3>
    <p>
      Вы понимаете и соглашаетесь с тем, что программное обеспечение UniLive и связанные с ним службы могут быть
      подвержены воздействию или нарушению множества факторов и что UniLive не гарантирует (включая, но не ограничиваясь
      этим), за исключением случаев, когда это прямо предусмотрено законодательством:
    </p>
    <p>
      1.1 Программное обеспечение UniLive и связанные с ним услуги полностью соответствуют потребностям пользователей;
    </p>
    <p>
      1.2 Отсутствие помех в работе UniLive, полное своевременное, безопасное, надежное или отсутствие ошибок; Любое
      программное обеспечение, услуги или другие материалы, полученные пользователем через UniLive, соответствуют
      ожиданиям пользователя;
    </p>
    <p>
      1.3 Любые ошибки в программном обеспечении UniLive будут исправлены.
    </p>
    <p>
      Пожалуйста, будьте осторожны и судите по собственному усмотрению в отношении информации, такой как сетевая
      информация, пароль счета, реклама или продвижение, которая подозревается в заимствовании или другом имуществе,
      иначе вы понесете прибыль, деловую репутацию, потерю данных или другие материальные или нематериальные потери, и
      UniLive не несет никакой прямой, косвенной, побочной, специальной, производной или штрафной юридической
      ответственности.
    </p>
    <p>
      Вы понимаете и соглашаетесь с тем, что при использовании программного обеспечения UniLive и связанных с ним услуг
      могут возникать такие факторы, как форс - мажор. В случае форс - мажорных обстоятельств UniLive будет стремиться к
      своевременному восстановлению в первый раз, но пользователь соглашается с тем, что UniLive не несет
      ответственности из - за потерь, вызванных форс - мажорными обстоятельствами.
    </p>
    <p>
      В соответствии с настоящим Соглашением UniLive приобретает право на обработку незаконного контента, которое не
      является обязательством или обязательством UniLive, и не гарантирует своевременное выявление нарушений или их
      обработку.
    </p>
    <p>
      Вы понимаете и соглашаетесь с тем, что UniLive не предоставляет никаких прямо выраженных или подразумеваемых
      гарантий или условий в отношении Услуг, включая, но не ограничиваясь этим, коммерческую применимость, применимость
      для конкретных целей и т.д.
    </p>
    <p>
      Вы понимаете и соглашаетесь с тем, что настоящее Соглашение составлено обеими сторонами на основе принципов
      соблюдения национальных законов и правил, соблюдения общественного порядка, защиты законных прав и интересов
      других лиц и улучшения опыта использования UniLive пользователями. Что касается вопросов, связанных с выполнением
      обязательств по проверке, регулированию или другим платформам, которые UniLive выполняет в процессе выполнения
      соглашения, то UniLive делает все возможное, чтобы выполнить и оценить работу в соответствии с соответствующими
      законами и правилами, но не гарантирует, что решение UniLive полностью согласуется с решением судебных или
      административных органов, если пользователь понимает и соглашается взять на себя ответственность за последствия.
    </p>
    <h3> пятнадцать, обработка дефолта </h3>
    <p>
      В случае нарушения вами настоящего Соглашения или других правил платформы UniLive, UniLive имеет право независимо
      судить и принимать меры, такие как предупреждение, исправление в течение ограниченного срока, ограничение
      некоторых или всех функций учетной записи, приостановление использования до прекращения предоставления услуг,
      закрытие учетной записи, запрет на перерегистрацию и т.д., и не несет юридической ответственности за любые
      последствия или потери, которые могут привести к тому, что вы не сможете надлежащим образом использовать свою
      учетную запись и связанные с ней услуги, а также не сможете надлежащим образом получить доступ к активам или иным
      интересам в своей учетной записи (включая, но не ограничиваясь этим, утрату или невозможность использования
      пользовательских данных, виртуальных активов и связанных с ними данных, продуктов или услуг). Компания UniLive
      имеет право сообщать о нарушениях и результатах их устранения, а также принимать решения о восстановлении
      использования учетной записи в зависимости от обстоятельств. В случае предполагаемого нарушения законов и правил,
      предполагаемого преступления, UniLive будет хранить соответствующие записи и имеет право в соответствии с законом
      сообщать об этом компетентным органам, сотрудничать с соответствующими компетентными органами в расследовании,
      сообщать об этом органам общественной безопасности и т. Д. В случае удаления контента UniLive имеет право не
      восстанавливать его.
    </p>
    <p>
      Если вы нарушаете положения настоящего Соглашения или других правил платформы UniLive, которые вызывают жалобы или
      иски третьих лиц, вы должны сами рассмотреть и взять на себя всю юридическую ответственность, которая может
      возникнуть в результате этого. Вы также должны полностью возместить убытки, понесенные компанией UniLive или ее
      аффилированными лицами в результате нарушения или неисполнения Вами своих обязательств, в результате чего компания
      UniLive или аффилированные с ней лица получили компенсацию или понесли наказание со стороны государственных
      органов.
    </p>
    <h3> шестнадцать, другие </h3>
    <p>
      Заголовки всех положений настоящего Соглашения являются удобными для чтения и сами по себе не имеют практического
      значения и не могут служить основой для толкования смысла настоящего Соглашения.
    </p>
    <p>
      2. Положения настоящего Соглашения по какой бы то ни было причине частично недействительны или не подлежат
      исполнению, остальные остаются в силе и являются обязательными для сторон.
    </p>


  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: "中文",
          label: "中文",
        },
        {
          value: "English",
          label: "English",
        },
        {
          value: "한국인",
          label: "한국인",
        },
        {
          value: "日本語",
          label: "日本語",
        }, {
          value: 'Русский',
          label: 'Русский'//俄国
        }, {
          value: 'Tiếng Việt',
          label: 'Tiếng Việt'//越南
        }, {
          value: 'ภาษาไทย',
          label: 'ภาษาไทย'//泰国
        }
      ],
      value: "",
      language: "",
    };
  },
  created() {
    // url获取参数
    this.language = this.$route.query.language;
    if (this.language == "zh_CN") {
      this.$router.push({
        path: "/UserServiceAgreementZh",
      });
    }
    if (this.language == "en_US") {
      this.$router.push({
        path: "/UserServiceAgreementEn",
      });
    }
    if (this.language == 'ko_KR') {
      this.$router.push({
        path: '/UserServiceAgreementKo'
      })
    }
    if (this.language == 'ja_JP') {
      this.$router.push({
        path: '/UserServiceAgreementJa'
      })
    }
    //俄语
    if (this.language == 'ru_RU') {
      this.$router.push({
        path: '/UserServiceAgreementRu'
      })
    }
    //越南语
    if (this.language == 'vi_VN') {
      this.$router.push({
        path: '/UserServiceAgreementVi'
      })
    }
    //泰语
    if (this.language == 'th_TH') {
      this.$router.push({
        path: '/UserServiceAgreementTh'
      })
    }
  },
  methods: {
    clickChange(value) {
      this.value = value;
      if (value == "中文") {
        this.$router.push({
          path: "/UserServiceAgreementZh",
        });
      }
      if (value == "English") {
        this.$router.push({
          path: "/UserServiceAgreementEn",
        });
      }
      if (value == "한국인") {
        this.$router.push({
          path: "/UserServiceAgreementKo",
        });
      }
      if (value == "日本語") {
        this.$router.push({
          path: "/UserServiceAgreementJa",
        });
      }
      if (value == 'Русский') {
        this.$router.push({
          path: '/UserServiceAgreementRu'
        })
      }
      if (value == 'Tiếng Việt') {
        this.$router.push({
          path: '/UserServiceAgreementVi'
        })
      }
      if (value == 'ภาษาไทย') {
        this.$router.push({
          path: '/UserServiceAgreementTh'
        })
      }
    },
  },
};
</script>

<style>
.container {
  padding: 0.1rem 0.3rem;
}

.lang {
  text-align: right;
  margin-bottom: 0.3rem;
}

h1,
h2 {
  color: #333;
  font-size: 0.5rem;
}

h3 {
  margin-top: 20px;
  font-size: 0.4rem;
}

p {
  margin-bottom: 15px;
  font-size: 0.3rem;
}

li {
  font-size: 0.3rem;
}
</style>
